import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import DatePicker from "react-datepicker";
import { Country } from "country-state-city";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "../App.css";
import API_PATH from "../Constants/api-path";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Undo,
  Link,
  Heading,
  List,
  Alignment,
  Indent,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";

import { Checkbox } from "antd";
import countryList from "./CountryList";
import { setSelectedProject } from "../Redux/features/project.slice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Col, Row } from "react-bootstrap";

export const CreateProjectModal = ({
  getProjectList = () => { },
  getholdProjectList = () => { },
  getcompleteProjectList = () => { },
  open = false,
  setOpen = () => { }
}) => {
  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const [form] = Form.useForm()
  const [startDate, setStartDate] = useState("");
  const [value, setValue] = useState("");
  const [inputList, setInputList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [, setSpinner] = useState(false);
  // const [isSubscribed, setIsSubscribed] = useState(false);
  // const [, setCall_recorded] = useState(0);
  const countries = Country?.getAllCountries();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = React.useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );

  const dispatch = useDispatch();
  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    expected_calls: "",
  });
  const handleClose = React.useCallback(() => {
    form.resetFields()
    setStartDate("");
    setValue("");
    setOpen(false)
    setEmailList([]);
    setOpen(false)
    dispatch(setSelectedProject({}));
    setInputList([]);
    setSelectList([]);
    setProjectDetails({
      project_title: "",
      expected_calls: "",
    });

  }, [dispatch, form, setOpen]);

  const handelChange = React.useCallback(
    (e) => {
      let key = e.target.name;
      let value = e.target.value;
      setProjectDetails({ ...projectDetails, [key]: value });
    },
    [projectDetails]
  );
  console.log(form.getFieldsValue());
  const handleEditorChange = React.useCallback((content) => {
    setValue(content);
  }, []);

  // const getProjects = React.useCallback(() => {
  //   axios
  //     .get(`${API_PATH.PRJECT_LIST}/${currentPage}`, {
  //       headers: headers,
  //     })

  //     .then((res) => {
  //       setLoading1(true);
  //       setAlldatas(res.data.count);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [currentPage, headers]);

  //COMMENTED COZ NOT FINDING THIS API ON SERVER ERROR
  // useEffect(() => {
  //   getProjects();
  // }, [currentPage, getProjects]);

  // const handelChecked = React.useCallback((e) => {
  //   if (e.target.checked) {
  //     setCall_recorded((current) => current + 1);
  //   } else {
  //     setCall_recorded((current) => current - 1);
  //   }
  //   setIsSubscribed((current) => !current);
  // }, []);

  const handleSubmit = React.useCallback(
    async (e) => {
      // e.preventDefault();
      setSpinner(true);

      let countryData = [];

      let companyData = [];
      let emailData = [];
      // if (loginDetails?.user?.email) {
      //   emailData?.push(loginDetails?.user?.email?.split("@")[0]);
      // }

      // for (var Count in selectList) {
      //   countryData?.push(selectList[Count]?.country);
      // }

      // for (var Company in inputList) {
      //   companyData?.push(inputList[Company]?.company);
      // }

      // for (var index in emailList) {
      //   if (
      //     emailList?.[index]?.value !== emailData?.[0] &&
      //     emailList?.[index]?.value !== ""
      //   ) {
      //     if (!emailData?.includes(emailList?.[index]?.value?.split("@")?.[0])) {
      //       emailData?.push(emailList?.[index]?.value?.split("@")?.[0]);
      //     }
      //   }
      // }
      let payload = {
        project_title: projectDetails?.project_title,
        user_id: loginDetails?.user?._id,
        invite_team: loginDetails?.user?.email && loginDetails?.user?.email?.split("@")?.[0] ? [...emailList?.map((list) => list?.split("@")?.[0]), loginDetails?.user?.email?.split("@")?.[0]]?.join(",") : emailList?.map((list) => list?.split("@")?.[0])?.join(","),
        country: selectList?.join(","),
        target_companies: inputList?.join(","),
        question: value,
        project_deadline: startDate !== "" ? startDate?.toISOString() : "", // Convert to ISO string
        expected_calls: projectDetails?.expected_calls,
      };
      // if (
      //   payload.project_title === "" ||
      //   payload.expected_calls === "" ||
      //   payload.question === "" ||
      //   payload.project_deadline === ""
      // ) {
      //   return toast.warning("All fields are mandatory!", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      //   });
      // }
      try {
        const res = await fetch(`${API_PATH?.CREATE_PROJECT}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        });
        const result = await res.json();
        if (
          result?.status === true &&
          result?.message === "Insert successfully."
        ) {
          setSpinner(false);
          getProjectList();
          handleClose();

          getholdProjectList();
          getcompleteProjectList();
          return toast.success("Project created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          handleClose();

          return toast.error(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong. Server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [
      emailList,
      getProjectList,
      getcompleteProjectList,
      getholdProjectList,
      handleClose,
      headers,
      inputList,
      loginDetails?.user?._id,
      loginDetails?.user?.email,
      projectDetails?.expected_calls,
      projectDetails?.project_title,
      selectList,
      startDate,
      value,
    ]
  );
  const getUsers = React.useCallback(async () => {
    axios
      .get(`${API_PATH.USER_MANAGEMENT}/${userData?.user?.company_id._id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        console.log(res, "ress");
        setUserList([...res.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [headers, userData?.user?.company_id._id]);
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Modal open={open}
        title={
          <div>
            <h4 className="mb-0"> Create New Project</h4>
            <p
              className="mb-0"
              style={{ color: "#526fd7", fontSize: "20px !important" }}
            >
              {" "}
              Detail out your requirement to launch a custom recruitment
              request{" "}
            </p>
          </div>
        }
        centered
        width={800}
        style={{
          height: 550,
          overflowY: "scroll"
        }}
        onCancel={() => {
          handleClose()
        }}

        footer={false}
      >
        <div >
          <Form form={form}
            onFinish={() => {
              console.log("finish?");
              handleSubmit()
            }} name="create_project_form">
            <Row className="">
              <Col md={12} className="">
                <Form.Item labelAlign="left" className="labelTop" name="project_title" label="Project Title" rules={[
                  {
                    required: true,
                    message: "Project Title is required",
                  },
                ]}>
                  <Input
                    type="text"
                    size="large"
                    placeholder="Project Title"
                    name="project_title"
                    value={projectDetails.project_title}
                    onChange={handelChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="">
              <Col md={6} className="">
                <Form.Item labelAlign="left" className="labelTop" name="expected_calls" label="Expected Calls" rules={[
                  {
                    required: true,
                    message: "Expected Calls is required",
                  },
                ]}>

                  <Select
                    name="expected_calls"
                    className="w-100"
                    placeholder="Expected Calls"
                    value={projectDetails.expected_calls || null}
                    onChange={(value) => {
                      setProjectDetails({ ...projectDetails, expected_calls: value });
                    }}
                    size="large"
                  >
                    <Select.Option value="1">1</Select.Option>
                    <Select.Option value="2">2</Select.Option>
                    <Select.Option value="3">3</Select.Option>
                    <Select.Option value="4">4</Select.Option>
                    <Select.Option value="5">5</Select.Option>
                    <Select.Option value="6">6</Select.Option>
                    <Select.Option value="7">7</Select.Option>
                    <Select.Option value="8">8</Select.Option>
                    <Select.Option value="9">9</Select.Option>
                    <Select.Option value="10">10</Select.Option>
                  </Select>
                </Form.Item>

              </Col>

              <Col md={6} className="">
                <Form.Item labelAlign="left" className="labelTop" name="project_deadline" label="Project Deadline" rules={[
                  {
                    required: true,
                    message: "Project Deadline is required",
                  },
                ]}>

                  <DatePicker
                    style={{ cursor: "default" }}
                    inputProps={{ readOnly: true }}
                    className="form-select"
                    minDate={new Date()}
                    value={startDate}
                    selected={startDate}
                    placeholderText="MM/DD/YYYY"
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row className="">
              <Col md={6} className="">
                <Form.Item labelAlign="left" className="labelTop" name="geography_of_experts" label="Geography Of Experts" rules={[
                  {
                    required: true,
                    message: "Geography of experts is required",
                  },
                ]}>

                  <Select
                    mode="multiple"
                    name="geography_of_experts"
                    showSearch
                    filterOption={(input, option) =>
                      option?.value?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="w-100"
                    options={countryList}
                    value={selectList}
                    placeholder="Select Country"
                    onChange={(value) => {
                      setSelectList(value)
                    }}
                  >   {countries?.map((country, index) => (
                    <Select.Option
                      key={index + 5}
                      value={country?.name}
                      label={country?.name}
                    >
                      {`${country?.name}`}
                    </Select.Option>
                  ))}</Select>
                </Form.Item>

              </Col>
              <Col md={6} className="col-md-6">
                <Form.Item labelAlign="left" className="labelTop" name="target_companies" label="Target Companies" rules={[
                  {
                    required: true,
                    message: "Target Companies are required",
                  },
                ]}>

                  <Select
                    mode="tags"
                    className="w-100"
                    open={false}
                    value={inputList}
                    placeholder="Target Companies"
                    onChange={(value) => {
                      setInputList(value)
                    }}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Item labelAlign="left" className="labelTop" name="team_member" label="Team Members" rules={[
                  {
                    required: true,
                    message: "Team members are required",
                  },
                ]}>

                  <Select
                    mode="multiple"
                    className="w-100"
                    name="Select Team Member"
                    showSearch
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    } placeholder="Select Team Member"
                    value={emailList}
                    options={userList?.map((item) => ({
                      label: item?.email,
                      value: item?.email,
                      key: item?._id,
                    }))}
                    onChange={(value) => {
                      setEmailList(value);
                    }}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={12} className="col-lg-12">
                <div className="form-group">
                  <div className="position-relative ckeditor_client">
                    <Form.Item labelAlign="left" className="labelTop" name="overview" label="Project Overview" rules={[
                      {
                        required: true,
                        message: "Project Overview is required",
                      },
                    ]}>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          // editorRef.current = editor;
                          console.info("Editor is ready to use!", editor);
                        }}
                        data={value}
                        config={{
                          toolbar: {
                            items: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "alignment:left",
                              "alignment:center",
                              "alignment:right",
                              "alignment:justify",
                              "|",
                              "bulletedList",
                              "numberedList",
                            ],
                          },
                          plugins: [
                            Bold,
                            Essentials,
                            Italic,
                            Paragraph,
                            Undo,
                            Link,
                            Heading,
                            List,
                            Alignment,
                            // BulletedList,
                            // NumberedList,
                            Indent,
                          ],
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                              },
                              {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                              {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                              },
                              {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                              },
                              {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                              },
                            ],
                          },
                          alignment: {
                            options: ["left", "center", "right", "justify"],
                          },
                        }}
                        onChange={(event, editor) => {
                          const newData = editor?.getData();
                          handleEditorChange(newData);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className="radio2">
              <Checkbox
                className="me-2"
                onChange={handelChecked}
                value={isSubscribed}
                name="call_recorded"
                style={{ color: "#526fd7" }}
                required
              ></Checkbox>

              <label style={{ color: "#526fd7" }}>
                Request calls to be recorded
              </label>
            </div> */}
            <div className="d-flex justify-content-end gap-2">
              <Form.Item labelAlign="left" className="labelTop">

                <Button
                  className="btn btntransparent"
                  htmlType="button"
                  // type="primary"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item labelAlign="left" className="labelTop">
                <Button
                  className="btn btnsm"
                  htmlType="submit"
                  type="primary"
                // onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal >
    </>
  );
};
